.App {
  font-family: Arial, sans-serif;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #333;
  color: white;
  padding: 10px 0;
  text-align: center;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.navbar li {
  margin: 0 15px;
}

.navbar a {
  color: white;
  text-decoration: none;
}

.main-container, .second-container, .third-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main-container .title {
  font-size: 3em;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  z-index: 1;
}

.main-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.second-container img {
  width: 150px;
  margin: 20px;
}

.second-container p, .third-container p {
  width: 80%;
  margin: 20px auto;
  font-size: 1.2em;
}

.third-container h2 {
  font-size: 2em;
}
