body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;

}

.container-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

table td{ 
  display: flex;
  flex-direction: row;
  align-items: start;
  margin: 10px;
  font-size: 1.2rem;
  
}

.download-button {
  background-color: #1e1e1e;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
}

.secondary-button {
  background-color: white;
  border: 1px solid #1e1e1e;
  color: #1e1e1e;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
  position: relative;
  bottom: -130px;
}

@media (min-width: 40rem){
  table td{ 
    align-items: center;    
  }
}