.navbar {
    background-color: #333;
    color: white;
    padding: 10px 0;
    text-align: center;
    z-index: 10;
  }
  
  .navbar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
  }
  
  .navbar li {
    margin: 0 15px;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    font-size: 1.2em;
  }
  